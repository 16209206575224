
export const encryptPassword = async (plaintext) => {
    // PUBLIC KEY
    const publicKeyPem = `-----BEGIN PUBLIC KEY-----
                        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtHIl1LC2IOJ2rlECY81t
                        pjU3G7KlW0Rj0QUYmRyqmOTVYHQ7j2lO9d0dccdVNN2q44riu4d69OrGxCq8M9tK
                        i2zSJ0SxwjiQiqsClaOYzCA5J8aOSbZZ1LsIWJiKs8FaPaPNc7zLBljVQkE98oMN
                        /rs4sdvDro2xg8b76auxGrmEXe+0VrqwH+HMkeiNyD7D62LMwU5J5gXd4ln1KBhT
                        JVJaSsExMztVESnhDBeX544DBsvMxNcxwQpxLjdhb7JNzm83OaXWgxyiDsWJJs7I
                        vGEZmR+0Iw7i2aJe36vC5hqiqb5LgExVYgFxNHRqMt+D5tBV1HHFdpvaI90un+V2
                        IwIDAQAB
                        -----END PUBLIC KEY-----`;
    const enc = new TextEncoder();
    const data = enc.encode(plaintext);
    const binaryDerString = atob(publicKeyPem.split('\n').slice(1, -1).join(''));
    const binaryDer = new Uint8Array(binaryDerString.length);
    for (let i = 0; i < binaryDerString.length; i++) {
        binaryDer[i] = binaryDerString.charCodeAt(i);
    }

    const publicKey = await window.crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        true,
        ['encrypt']
    );

    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
        },
        publicKey,
        data
    );
    let cipher = btoa(String.fromCharCode(...new Uint8Array(encrypted)));
    return cipher
}
