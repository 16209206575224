import React, { useState } from "react";
import { Modal, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const HowToUserGuide = (props) => {

    const [showMenuItem, setShowMenuItem] = useState("");


    return (<>
        <NavDropdown
            active={true}
            title={<FontAwesomeIcon title="User Guide" icon={faQuestionCircle} />}
            className="icon-menu"
        >
            <NavDropdown.Item onClick={e => {
                setShowMenuItem("Pricing Analytics");
            }}>Pricing Analytics</NavDropdown.Item>
            <NavDropdown.Item onClick={e => {
                setShowMenuItem("Price Engine");
            }}>Price Engine</NavDropdown.Item>
            <NavDropdown.Item onClick={e => {
                setShowMenuItem("Add A Match");
            }}>Add A Match</NavDropdown.Item>
        </NavDropdown>
        <Modal
            size="lg"
            show={showMenuItem} 
            onHide={e => setShowMenuItem("")}
            centered 
        >
            <Modal.Header closeButton>
                <div className="modal-title">
                    <div className="title h4 mb-0">{showMenuItem}</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {(showMenuItem == "Pricing Analytics") ? <iframe src="https://player.vimeo.com/video/1027758445?h=df35cc81a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1" frameborder="0" scrolling="no" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="PRICING ANALYTICS - Final - HD 1080p.mov" style={{width: "100%", height: "450px"}}></iframe> : ""}
                {(showMenuItem == "Price Engine") ? <iframe src="https://player.vimeo.com/video/1027758538?h=7a0ec48800&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1" frameborder="0" scrolling="no" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="PRICING ANALYTICS - Final - HD 1080p.mov" style={{width: "100%", height: "450px"}}></iframe> : ""}
                {(showMenuItem == "Add A Match") ? <iframe src="https://player.vimeo.com/video/1010738163?h=6af33b1c99&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1" frameborder="0" scrolling="no" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="PRICING ANALYTICS - Final - HD 1080p.mov" style={{width: "100%", height: "450px"}}></iframe> : ""}
            </Modal.Body>
        </Modal>
    </>);
};

export default HowToUserGuide;