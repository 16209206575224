import axios from "axios";
import config from "../../config/config.js";
import { loginApi, passThroughApi , signUpApi, tenantInfoApi, reportsApi, ZONES } from "./constants.js";
import { decodeToken } from "react-jwt";
import * as pageRoutes from "../../pageRoutes.js";
import qs from "qs";

import { checkSessionStatus } from "../../utils/loginSessionOperations";
import { getQueryParams } from "../../utils/routeBasedOperations";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faInfoCircle, } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import {
  setLocalStorageValue,
  clearAllLocalStorageValue,
  removeValueFromLocalStorage
} from "../../utils/localStorageOperations";
import "react-toastify/dist/ReactToastify.css";
import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter.js";
import filterArrConfig from "../common/Header1/filterArrConfg.json"
toast.configure();

const redirectPageToLogin = (authFailed) => {
  const idToken = localStorage.getItem("id_token");
  if (!idToken || authFailed) {
    window.location.href = pageRoutes.loginPage + "?error=true";
  }
};

export const hardApiCall = async (method, url, headers = {}, data = {}) => {
  const newUrl = url;
  let idToken = localStorage.getItem("id_token");
  const tenant = localStorage.getItem("tenant");
  const loginPageCheck = (window.location.pathname !== pageRoutes.loginPage && window.location.pathname !== pageRoutes.signUpPage);
  let waitForSessionCheck = true;
  if (url != loginApi) {
    waitForSessionCheck = await checkSessionStatus(idToken).then(
      (response) => response
    );
  }
  const checkAuthToken = idToken && waitForSessionCheck;
  if (!checkAuthToken && loginPageCheck) {
    window.location.href = pageRoutes.loginPage + "?error=true";
  }
  idToken = localStorage.getItem("id_token");
  let decodedObj = decodeToken(idToken);
  let accessToken = localStorage.getItem("accessToken");
  const newHeaders = {
    ...headers,
    ...{
      "Content-Type": "application/json", //"application/json",
      "idtoken": idToken,
      //"Authorization": "Bearer " + accessToken,
      "X-Bungee-Tenant": tenant ? tenant : null
    },
  };
  let axiosOption = {
    method,
    url: newUrl,
    headers: newHeaders,
    // mode: "no-cors",
    credentials: "omit",
    data: qs.stringify(data),
  };
  if (method.toLowerCase() == 'get') {
    //console.log('data parems', data);
    axiosOption['params'] = data;
  } else {
    //axiosOption['data'] = qs.stringify(data)
    axiosOption['data'] = data;
  }
  return axios(axiosOption).then((response) => {
    if (response.data === "Invalid token") {
      redirectPageToLogin(true);
      return false;
    }
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  });
};

export const keyExistsInAllObjects = (array, key) => {
  for (const obj of array) {
    if (!obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const capitalizeObjectKeys = (obj) => {
  const capitalizedObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      capitalizedObj[capitalizedKey] = obj[key];
    }
  }
  return capitalizedObj;
}

// axios is seperately being used in src/utils/loginSessionOperations.js
export const apiCall = async (method, url, headers = {}, data = {},isBntAPI = false, isReportbaasAPI = false, isNotificationAPI = false) => {
  let newUrl = '';
  if (isBntAPI) {
    newUrl = config.bntEndPoint + url;
  } else if (isReportbaasAPI) {
    newUrl = config.reportsBaseUrl + url;
  } else if (isNotificationAPI) {
    newUrl = config.notificationsBaseUrl + url;
  } else {
    newUrl = config.base_url + url;
  }
  let idToken = localStorage.getItem("id_token");
  const loginPageCheck = (window.location.pathname !== pageRoutes.loginPage && window.location.pathname !== pageRoutes.signUpPage && !window.location.pathname.includes('passThrough') );
  let waitForSessionCheck = true;
  let passThrough = JSON.parse(localStorage.getItem("passThrough"));
  let sessionCheckList = [ loginApi, passThroughApi, signUpApi]
  if (!sessionCheckList.includes(url) && !passThrough) {
    waitForSessionCheck = await checkSessionStatus(idToken).then(
      (response) => response
    );
  }
  const checkAuthToken = idToken && waitForSessionCheck;
  if (!checkAuthToken && loginPageCheck) {
    window.location.href = pageRoutes.loginPage + "?error=true";
  }
  idToken = localStorage.getItem("id_token");
  const tenant = localStorage.getItem("tenant");
  let accessToken = localStorage.getItem("access_token");
  const newHeaders = {
    ...headers,
    ...{
      "Content-Type": "application/json", //"application/json",
      "idToken": "Bearer " + idToken,
      "Authorization": "Bearer " + accessToken,
      "X-Bungee-Tenant": tenant ? tenant : null
    },
  };
  let passThroughAccess = JSON.parse(localStorage.getItem('passThrough'))
  if(passThroughAccess && localStorage.getItem('current_location')){
    newHeaders["X-Tenant-Location"] = localStorage.getItem('current_location');
  }

  //if (loginPageCheck) {
  //data.idtoken = idToken;
  //redirectPageToLogin();
  //}
  let axiosOption = {
    method,
    url: newUrl,
    headers: newHeaders,
    // mode: "no-cors",
    credentials: "omit",
    data: qs.stringify(data),
  };
  if (method.toLowerCase() == 'get') {
    //console.log('data parems', data);
    axiosOption['params'] = data;
  } else {
    //axiosOption['data'] = qs.stringify(data)
    axiosOption['data'] = data;
  }
  return axios(axiosOption).then((response) => {
    if (response.data === "Invalid token") {
      redirectPageToLogin(true);
      return false;
    }
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  });
};

export const convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  if (array.length) {
      let line = '';
      for (let index in array[0]) {
          if (line !== '') line += ',';

          line += '\"' + index + '\"';
      }
      str += line + '\r\n';
  }

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ',';

      line += '\"' + array[i][index] + '\"';
    }
    str += line + '\r\n';
  }
  
  return str;
};

export const loadCustomApiList = (configObj, callBackLoadCustomApiList) => {
    let filterBtnsSelected = configObj.filterBtnsSelected;
    let keyWord = Array.from(filterBtnsSelected[configObj.name])[0];
    let keyWordConfig = {
      "retailer": "comp_source",
      "report_category": "tenant_category",
      "report_subcategory": "tenant_subcategory",
      "report_subsubcategory": "tenant_sub_subcategory"
    };
    let postBody = {
      sort_by: keyWordConfig[configObj.name],
      filters: {}
    };
    if ('customApiValues' in configObj) {
      ["retailer", "report_category", "report_subcategory", "report_subsubcategory"].map(fieldName => {
        if (fieldName in configObj['customApiValues']) {
          postBody['filters'][keyWordConfig[fieldName]] = configObj['customApiValues'][fieldName];
        }
      });
    }
    if (keyWord) {
      let limit = 3;
      if (keyWord.includes('top')) {
        postBody.sort = "asc";
        limit = keyWord.replace('top', '');
      } 
      if (keyWord.includes('bottom')) {
        postBody.sort = "desc";
        limit = keyWord.replace('bottom', '');
      }
      postBody.limit = limit;
    }
    if (filterBtnsSelected.report_comparison_period.size) {
      postBody['interval'] = Array.from(filterBtnsSelected.report_comparison_period)[0];
    }
    if (filterBtnsSelected.report_cpi.size) {
      postBody['cpi_type'] = Array.from(filterBtnsSelected.report_cpi)[0];
    }
    let apiUrl = reportsApi + '/executive-summary/autocomplete';
    apiCall("POST", apiUrl, {}, postBody)
    .then((response) => {
      if (response.data) {
        callBackLoadCustomApiList(response.data);
      }
  })
  .catch((error) => {
      console.log("ERROR", error);
      callBackLoadCustomApiList([]);
  });
}

export const numberWithCommas = (x) => {
  if (x == null) {
    x = "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const generatePaginationString = (totalCount,limit) => {
  let limitString = limit;
  if(totalCount < limit)
  {
    limitString = totalCount
  } 
  return limitString + ' Of ' + numberWithCommas(totalCount) + ' Result';
}

const endcodString = (string) => {
  let endcString = string.replace("&", "%26");
  return endcString;
};

export const addParamsToLink = (to, params = {}) => {
  let newUrl = to;
  let paramDivider = "?";
  let allParams = getQueryParams();
  allParams = {...allParams, ...params};

  for (var ele in allParams) {
    newUrl += `${paramDivider}${ele}=${endcodString(allParams[ele])}`;
    paramDivider = "&";
  }
  // console.log(newUrl, "param");
  return newUrl ? newUrl : "";
};

export const getdemoFlag = () => {
  return (localStorage.getItem('tenant') == 'topco'  || localStorage.getItem('tenant') == 'bjs');
}

export const getCustomLabel = (labelName) => {
  const filterConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["download_config"];
  let customLabel = undefined;
  if (filterConfig && labelName) {
    customLabel = filterConfig?.[`${labelName.toLowerCase().replace(/tenant|old|new|original|_| /g, "")}_download`];
    customLabel = customLabel ? customLabel.replace(/ /, "\n") : customLabel;
    if (labelName.includes('New') && customLabel!== undefined) { customLabel = 'New ' + customLabel; }
    else if (labelName.includes('Old') && customLabel!== undefined) { customLabel = 'Old ' + customLabel; }
    else if (labelName.includes('Original') && customLabel!== undefined) { customLabel = 'Original ' + customLabel; }
  }
  return customLabel ? customLabel : labelName;
};

export const getLabelInDownloadFile = (labelName) => {
  const downloadConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["download_config"];
  let customLabel = undefined;
  if (downloadConfig) {
    customLabel = downloadConfig?.[`${labelName.toLowerCase().replace(/tenant|_| /g, "")}_download`];
  }
  if(labelName == 'units_sold_monthly')
  {
    customLabel = 'Units Sold'
  }
  return customLabel ? customLabel : labelName;
}

export const getShortLabel = (labelName) => {
  let shotString = labelName;
  /*if (shotString.length > 9) {
    if (["estimated rev.", "3rd party present"].includes(shotString.toLowerCase())) {
      return shotString;
    }
    shotString = shotString.slice(0, 9);
    return shotString + "...";
  }*/
  return shotString;
};

export const getDownloadThresold = () => {
  let downloadThresold = localStorage.getItem("downloadThresold");
  downloadThresold = downloadThresold !== 'undefined' ? parseInt(downloadThresold) : 0;
  return downloadThresold;
};

export const checkSuppressionFlag = (row) => {
  let flagHtml = "";
  if (row.isSuppressionApplied) {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-warning float-start">Marked For Suppression </div></>;
  } else if (row.isMarkedToOnboard) {
    flagHtml = <>
      <div className="clearfix"></div>
      <div className="badge bg-success float-start">Marked For Onboarding for {getDateDifferenceInDays(String(row.created_date))} Day(s)</div>
    </>;
  }
  return flagHtml;
};

export const checkFavoriteFlag = (row) => {
  let flagHtml = "";
  if (row.isFavorite) {
    flagHtml = <><FontAwesomeIcon style={{ "color": "0069BF" }} className="fa-light" icon={faBookmark} /></>;
  }
  return flagHtml;
};

export const checkUnSuppressionFlag = (row) => {
  let flagHtml = undefined;
  if (row.etl_status == 'deleted') {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-warning float-start">Marked For Unsuppression </div></>;
  }
  return flagHtml;
};

export const brandGapColumnsToDownload = () => {
  const columnsToDownload = ['normalised_brand','sub_brand', 'tenant_category', 'tenant_category_count', 'tenant_subcategory', 'tenant_subcategory_count',
    'tenant_sub_subcategory', 'tenant_sub_subcategory_count', 'tenant_sub_sub_subcategory', 'tenant_sub_sub_subcategory_count', 'product_count',
    'amazonChoice', 'rating', 'review_count', 
    'questionsCount', 'competitor_list', 'competitor_count',].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const productGapColumnsToDownload = () => {
  const columnsToDownload = ['bungee_product_id','product_title', 'product_url', 'normalised_brand', 'sub_brand','upc', 'sku',
    'tenant_category', 'tenant_subcategory', 'tenant_sub_subcategory', 'tenant_sub_sub_subcategory',
    'brand_gap', 'best_seller_flag', 'estimated_revenue', 'third_party', 'competitor_list', 'competitor_count',
    'rating', 'review_count', 'non_amazon_review_count', 'effective_price',
    'size', 'category_rank', 'first_seen', 'private_label', 'competitor_category', 'number_of_competitors_product',
    'number_of_competitors_brand', 'competitors_carry_product', 'competitors_carry_brand', 'longest_side', 'median_side', 
    'shortest_side', 'length_girth', 'maximum_weight', 'asns', 'fulfilled_by_competitor', 'parent_sku', 
    'product_data_identifier', 'shipping_weight', 'availability'].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const ubrGapColumnsToDownload = () => {
  const columnsToDownload = ['bungee_product_id', 'product_title', 'product_url', 'normalised_brand','sub_brand', 'upc', 'sku',
    'tenant_category', 'tenant_subcategory', 'tenant_sub_subcategory', 'tenant_sub_sub_subcategory',
    'brand_gap', 'best_seller_flag', 'estimated_revenue', 'third_party', 'competitor_list',
    'rating', 'review_count', 'non_amazon_review_count', 'effective_price', 'size', 'category_rank',
    'first_seen', 'private_label', 'competitor_category', 'number_of_competitors_product',
    'number_of_competitors_brand', 'competitors_carry_product', 'competitors_carry_brand', 'longest_side', 'median_side',
    'shortest_side', 'length_girth', 'maximum_weight', 'asns', 'fulfilled_by_competitor', 'parent_sku',
    'product_data_identifier', 'shipping_weight', 'availability','units_sold_monthly'].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const productsPricingColumnsToDownload = () => {
  const columnsToDownload = ['tenant_product_title', 'tenant_product_url','tenant_image_url','tenant_sku', 'tenant_upc',
    'tenant_category', 'tenant_subcategory', 'tenant_sub_subcategory', 'tenant_sub_sub_subcategory',
    'tenant_brand', 'tenant_effective_price', 'cpi', 'comp_equalized_min_price', "comp_source","size","uom","ppu","tenant_stock_status_flag","tenant_cost_price","tenant_margin_price","tenant_stock_quantity"].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const suggestionListingColumnsToDownload = () => {
  const columnsToDownload = ['tenant_sku','tenant_mfg_sku','tenant_product_url','tenant_product_title','tenant_category','tenant_subcategory','tenant_upc','tenant_brand','tenant_stock_quantity','tenant_caliber','tenant_cost','tenant_margin','tenant_price','tenant_margin_percentage','tenant_markup_percentage','tenant_profit','tenant_map_price','tenant_msrp_price',
    'comp_min_price','comp_max_price','comp_mean_price','tenant_cost_price_per_round','margin_suggested_price','competitor_suggested_price','margin_suggested_price_with_roundoff','competitor_suggested_price_with_roundoff','m_suggested_price_per_round','c_suggested_price_per_round',
    'm_suggested_margin_percentage','c_suggested_margin_percentage','m_suggested_markup_percentage','c_suggested_markup_percentage','m_suggested_price_profit','c_suggested_price_profit','comp_min_price_in_stock','comp_max_price_in_stock','comp_mean_price_in_stock',
    'margin_suggested_price_with_shipping','competitor_suggested_price_with_shipping','margin_suggested_price_with_roundoff_with_shipping','competitor_suggested_price_with_roundoff_with_shipping','m_suggested_price_per_round_with_shipping','c_suggested_price_per_round_with_shipping',
    'm_suggested_margin_percentage_with_shipping','c_suggested_margin_percentage_with_shipping','m_suggested_markup_percentage_with_shipping','c_suggested_markup_percentage_with_shipping','m_suggested_price_profit_with_shipping','c_suggested_price_profit_with_shipping'
].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload
}

export const onboardingProductColumnsToDownload = () => {
  const columnsToDownload = ['bungee_product_id','product_title', 'product_url', 'normalised_brand', 'upc', 'sku',
    'tenant_category', 'tenant_subcategory', 'tenant_sub_subcategory', 'tenant_sub_sub_subcategory',
    'brand_gap', 'competitor_list', 'competitor_count', 'created_date',
    'created_by', 'reason_name', 'comment', 'status_name'].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const suppressionProductColumnsToDownload = () => {
  const columnsToDownload = ['bungee_id','product_title', 'product_url', 'comp_brand', 'comp_upc', 'comp_sku',
    'category', 'subcategory', 'sub_subcategory', 'sub_sub_subcategory',
    'comp_source', 'competitor_count', 'created_date',
    'created_by', 'reason_name', 'comment', 'updated_by','updated_date'].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const onboardingBrandColumnsToDownload = () => {
  const columnsToDownload = ['normalised_brand', 'tenant_category', 'tenant_category_count', 'tenant_subcategory', 'tenant_subcategory_count',
    'tenant_sub_subcategory', 'tenant_sub_subcategory_count', 'tenant_sub_sub_subcategory', 'tenant_sub_sub_subcategory_count', 'product_count',
    'competitor_list', 'competitor_count', 'created_date',
    'created_by', 'reason_name', 'comment', 'status_name'].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const suppressionBrandColumnsToDownload = () => {
  const columnsToDownload = ['comp_brand', 'category', 'subcategory',
  'sub_subcategory', 'sub_subcategory', 'sub_sub_subcategory',
  'comp_source', 'competitor_count', 'created_date',
  'created_by', 'reason_name', 'comment','updated_by', 'updated_date'].filter(lableName => isColumnAllowed(lableName)).map(labelName => getLabelInDownloadFile(labelName));
  return columnsToDownload;
};

export const getReviewCountMaxSuggestions = () => {
  const review_range = ['21', '51', '101', '501', '1001',
    '2001', '3001', '4001', '5001', '6001', '7001', '8001', '9001', '10001'];
  const suggestion = review_range.map((range) => {
    return { label: range, value: range };
  })
  // Default value
  // suggestion.unshift({ 'review_count': '0-999999' });
  return suggestion;
};

export const getReviewCountMinSuggestions = () => {
  const review_range = ['20', '50', '100', '500', '1000', '2000',
    '3000', '4000', '5000', '6000', '7000', '8000', '9000', '10000',];
  const suggestion = review_range.map((range) => {
    return { label: range, value: range };
  })
  // Default value
  // suggestion.unshift({ 'review_count': '0-999999' });
  return suggestion;
};

export const dateString = (string) => {
  let date = string.toString();
  date = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
  return date;
};

export const handleDateColumn = (dateData) => {
  if(!dateData) return 
  let dateValue = "";
  if (dateData == -1) {
  
  } else {
    // const dateDiff = (new Date(dateString(dateData)).getFullYear() - (new Date()).getFullYear());
    if (dateData.toString().startsWith("21")) {
      dateValue = "Permanent"
    } else {
      dateValue = formatDate(String(dateData));
    }
  }
  return dateValue;
};

export const handlePriceRange = (minPrice, maxPrice) => {
  if (minPrice === 'NA' && maxPrice === 'NA') {
    return "NA";
  }
  else if (minPrice === 'NA' && maxPrice !== 'NA') {
    return "$" + maxPrice;
  }
  else if (minPrice !== 'NA' && maxPrice === 'NA') {
    return "$" + minPrice;
  }
  else {
    return "$" + minPrice + " - " + maxPrice;
  }
};

export const handleUpdatedBy = (updatedBy) => {
  return updatedBy === 'EMPTY' ? "" : updatedBy;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isOnlyDigits = (val) => {
  return /^\d+$/.test(val);
};

const renderTooltip = (message, props) => (
  <Tooltip id="button-tooltip" {...props}>
      {message}
  </Tooltip>
);

export const toolTipMessage = (message, placement) => {
  return (<OverlayTrigger
      message={message}
      placement={placement ? placement : "bottom"}
      delay={{ show: 250, hide: 400 }}
      overlay={e => renderTooltip(message, e)}
  >
      <span><FontAwesomeIcon className="fa-sm ms-1 text-secondary" icon={faInfoCircle} /></span>
  </OverlayTrigger>);
};

export const checkExtraFilterConfig = (filter) => {
  delete(filter['customApiList']);
  if (window.location.pathname == '/reports/executivesummary') {
    let executiveSummaryLocal = localStorage.getItem('ExecutiveSummary');
    let executiveSummaryJson = JSON.parse(executiveSummaryLocal).report_filter_top_bottom_config;
    if (executiveSummaryJson) {
      if (filter.accessor in executiveSummaryJson && 'customApiList' in executiveSummaryJson[filter.accessor]) {
        filter['customApiList'] = executiveSummaryJson[filter.accessor]['customApiList'];
      }
    } 
  }
  return filter;
};

export const generateFiltersArr = (filterConfig, filterBtnsSelected) => {
  let filterArrayConfig = filterArrConfig;
  let filters = [];
  if (filterConfig) {
    filterConfig.map(filterConfigSingle => {
      if (filterArrayConfig[filterConfigSingle] && filterBtnsSelected && filterBtnsSelected[filterConfigSingle]) {
          let newFilter = filterArrayConfig[filterConfigSingle];
          newFilter = checkExtraFilterConfig(newFilter);
          filters.push(newFilter);
      }
    });
  }
  return filters;
};

export const generateFiltersMap = (filterConfig, filterBtnsSelected) => {
    let filterMap = {};
    if (filterConfig && filterConfig.length && filterBtnsSelected) {
        for (let key of Object.keys(filterBtnsSelected)) {
            if (filterConfig.includes(key)) {
                filterMap[key] = [...filterBtnsSelected[key]];
            }
        }
    }
    return filterMap;
};


export const getDownloadModalFilterData = (filters, filtersContext) => {
  return filters.map(({label, accessor, name}) => {
    const customLabel = getCustomLabel(label);
    const filterValue = filtersContext[accessor] ? Array.from(filtersContext[accessor]) : [];
    let trimmedFilterValue = [];
    if (name === 'rating' || name === 'review_count') {
      trimmedFilterValue = filterValue.join("-");
    } else {
      trimmedFilterValue = filterValue.length > 2 ? `${filterValue[0]}, ${filterValue[1]} +${filterValue.length - 2}` : `${filterValue}`;
    }
    return <p className="my-1">{<b title={customLabel}>{customLabel}</b>}: <span title={filterValue.toString()}>{trimmedFilterValue || 'NA'}</span></p>
  })
};

export const columnsMapping = (resultWithOldSchema) => {
  if (!resultWithOldSchema['tenant_stock_status_flag']) {
    resultWithOldSchema['tenant_stock_status_flag'] = true;
  }
  if (!resultWithOldSchema['comp_stock_status_flag']) {
    resultWithOldSchema['comp_stock_status_flag'] = true;
  }
  let LABELMAP = {
    'source': 'retailer',
    'price_leader': 'retailer',
    'comp_source': 'retailer',
    'normalised_brand': 'brand',
    'tenant_brand': 'brand',
    'tenant_category': 'category',
    'tenant_subcategory': 'subcategory',
    'tenant_sub_subcategory': 'subsubcategory',
    'tenant_sub_sub_subcategory': 'subsubsubcategory',
    'best_seller_flag': 'best_seller',
    'image_competitors': 'image_competitor',
    'price_type': 'price',
    'priority': 'tenant_priority',
  };
  const filterConfigValues = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["filter_config_values"];
  let VALUEMAP = {
    'best_seller': {
      "true": 'TRUE',
      "false": 'FALSE'
    },
    'image_competitor': {
      "true": 'TRUE',
      "false": 'FALSE'
    },
    'brand_gap': {
      "true": 'Gap Brands',
      "false": 'Existing Brands'
    },
    'private_label': {
      "true": 'Only Private Labels',
      "false": 'Exclude Private Labels'
    },
    'third_party': {
      "true": '3P',
      "false": '1P'
    },
    'comp_stock_status_flag': {
      "true": 'Yes',
      "false": 'All'
    },
    'is_cpi_outlier' : {
      'true': 'Y',
      'false': 'N'
    },
    'tenant_stock_status_flag': {
      'true': 'Yes',
      'false': 'All'
    },
    'price': {
      'effective_price': 'Effective Price',
      'regular_price' : 'Regular Price',
      'promo_price' : 'Promo Price'
    },
  };
  if(filterConfigValues != null && 'priority_filter' in filterConfigValues)
  {
    VALUEMAP ={
      ...VALUEMAP,
      'tenant_priority' :{
        'High' : 'Y',
        'Low' : 'N',
      }
    }
  }
  if (!resultWithOldSchema) {
    resultWithOldSchema = {};
  }
  const resultWithNewSchema = {};
  Object.keys(resultWithOldSchema).map(key => {
    const newKey = LABELMAP[key] || key;
    let newValue = '';
    if (newKey === 'review_count' || newKey === 'rating') {
      newValue = Object.values(resultWithOldSchema[key]);
    } else {
      newValue = (newKey in VALUEMAP) ? VALUEMAP[newKey][resultWithOldSchema[key]] : resultWithOldSchema[key]
    }
    resultWithNewSchema[newKey] = Array.isArray(newValue) ? new Set(newValue) : new Set([newValue]);
  });
  return resultWithNewSchema;
};

export const isSuggestionTypeZone = () => {
  let iszoneLevel = false;
  if (localStorage.getItem('zoneLevelSuggestions') === "true") {
    iszoneLevel = true;
  }
  return iszoneLevel;
};

export const isPriceEditTypeZone = () => {
  let iszoneLevel = false;
  if (localStorage.getItem('ZoneLevelPriceEdit') === "true") {
    iszoneLevel = true;
  }
  return iszoneLevel;
};


export const isThisFeatureAllowed = (featureName) => {
  const allowedFeatureConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["allowedFeatures"];
  let response = false;
  if (featureName && allowedFeatureConfig) {
    response = allowedFeatureConfig.includes(featureName);
  }
  if (localStorage.getItem('tenant')=='topco' && (featureName == 'suppression' || featureName=='edit' || featureName=='favorite')){
    response = true
  }
  return response;
};

export const getCPIWeightTypeValue = () => {
  const tenantFeatureConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.dashboard_cpi_chart_config || {};
  const defaultType = tenantFeatureConfig.default_cpi_type || '';
  const dropDownOptions = tenantFeatureConfig.cpi_type || [];
  
  const options = dropDownOptions.map(row => {
    const obj = {
      label: row === "weighted" ? 'Weighted' : 'Non Weighted', 
      value: row === "weighted" ? 'weighted' : 'non_weighted', 
      is_default: row === defaultType 
    };
    return obj;
  });

  return options;
}

export const getCPIChartTypeValue = () => {

  const tenantFeatureConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.dashboard_cpi_charttype_config || {};
  const dropDownOptions = tenantFeatureConfig.cpi_charttype_type || [];
  return dropDownOptions;
}

export const generateCpiTypeColor = (cpiType) => {
  if (cpiType && cpiType.toLowerCase() == "equal") {
      return '#ff710b';
  } else if (cpiType && cpiType.toLowerCase() == "expensive") {
      return '#ff360a';
  } else {
      return '#00bf58';
  }
};

export const UpdateFiltersWithFavoriteFilters = () => {
  // Get Favorite filter values form local-storage
  const favoriteFilter = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["favoriteFilter"];
  const filterConfigName = favoriteFilter?.filterconfigname, filterConfigId = favoriteFilter?.id, filterConfigValues = columnsMapping(favoriteFilter?.filterconfigvalues);
  return { filterConfigId, filterConfigName, filterConfigValues, isFavorite: Boolean(filterConfigName) };
};


export const boolImgRetailer = (valueToCheck, retailers, imageRetailers) => {
  const [img_comp] = Array.from(valueToCheck);
  if (img_comp && img_comp === 'Yes') {
    return imageRetailers;
  } else if (img_comp && img_comp === 'No') {
    return retailers.filter(retailer => !imageRetailers.includes(retailer));
  } else {
    return retailers;
  }
};

export const imageSource = (src) => {
  let newSrc = src;
  if (!newSrc) {
    newSrc = '/images/default-image.svg';
  }
  return newSrc;
}

export const noImageCallBack = (e) => {
  e.target.src = "/images/default-image.svg";
  e.target.title = "No Image Available for the Product!";
};

export const isColumnAllowed = (columnName) => {
  const columnMapDict = {
    'promo_price_cpi': 'Promo Price CPI',
    'tenant_promo_price_double': 'Promo Price',
    'tenant_previouse_price': 'Price Change',
    'tenant_reg_price': 'Regular Price',
    'tenant_promo_price': 'Promo Price',
    'last_price_change_date':'Last Price Change Date',
    'comp_prev_price':'Previous Price',
    'comp_price':'Current Price'
  };
  const downloadConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["download_config"];
  let customLabel = undefined;
  if (downloadConfig) {
    customLabel = downloadConfig?.[`${columnName.toLowerCase().replace(/tenant|old|new|original|_| /g, "")}_download`];
  }
  if(columnMapDict[columnName]) {
    customLabel = columnMapDict[columnName];
  }
  return customLabel ? true : false;
};

export const isFilterAllowed = (filterName) => {
  if(filterName == 'tenant_stock_status_flag' || filterName == 'comp_stock_status_flag'){
    return true;
  }
  if (filterName == 'comp_zipcode' && isThisFeatureAllowed(ZONES)) {
    return true;
  }
  const filterConfig = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["filter_config"];
  let customLabel = undefined;
  if (filterConfig && filterName) {
    customLabel = filterConfig?.[`${filterName.toLowerCase().replace(/tenant|_| /g, "")}_filter`];
    customLabel = customLabel ? customLabel.replace(/ /, "\n") : customLabel;
  }
  return customLabel ? true : false;
};

export const keepActionDisabled = (selectedList) => {
  return (selectedList.some(e => e.isSuppressionApplied) || selectedList.some(e => e.isMarkedToOnboard));
};

export function formatDate(dateString) {
  if(!dateString){
    return '';
  }
  const year = dateString.slice(0, 4);
  const month = parseInt(dateString.slice(4, 6)) - 1; // Months are zero-based
  const day = dateString.slice(6, 8);

  const date = new Date(year, month, day);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short'
  });

  return formattedDate;
};

function getDateDifferenceInDays(dateString) {
  if (!dateString) {
    return '';
  }
  const year = dateString.slice(0, 4);
  const month = parseInt(dateString.slice(4, 6)) - 1; // Months are zero-based
  const day = dateString.slice(6, 8);
  const createdDate = new Date(year, month, day);
  const currentDate = new Date();
  const differenceInMilliseconds = Math.abs(currentDate - createdDate);
  const differenceInDays = Math.ceil(differenceInMilliseconds / (24 * 60 * 60 * 1000));
  return differenceInDays;
};

export function getAgeingFlag(dateString) {
  if (!dateString) {
    return 'NA';
  }
  const differenceInDays = getDateDifferenceInDays(dateString);
  let flagHtml = '';
  if (differenceInDays <= 30) {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-success float-start"><span style={{fontSize: "12px"}}>{differenceInDays} Day(s)</span></div></>;
  } else if (differenceInDays > 30 && differenceInDays <= 90) {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-warning float-start"><span style={{fontSize: "12px"}}>{differenceInDays} Day(s)</span></div></>;
  } else {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-danger float-start"><span style={{fontSize: "12px"}}>{differenceInDays} Day(s)</span></div></>;
  }
  return flagHtml;
};

export function roundStringToTwoDecimalPlaces(str) {
  // Convert the string to a number
  let num = parseFloat(str);

  // Check if the conversion was successful
  if (isNaN(num)) {
    throw new Error('Invalid input: Not a number.');
  }

  // Round the number to two decimal places
  let roundedNum = Math.round(num * 100) / 100;

  // Convert the rounded number back to a string
  let roundedStr = roundedNum.toFixed(2);

  return roundedStr;
}

export function getLatestRefreshDate(){
    apiCall("GET", tenantInfoApi, {}, {})
    .then((response) => {
      if (response?.data && response?.data?.data) {
        if (Object.keys(response.data.data).length) {
          let newRelease = response?.data?.data?.new_release
          if(newRelease){
            let passThroughAccess = JSON.parse(localStorage.getItem("passThrough"))
            clearAllLocalStorageValue();
            toast.warning(`Session expired due to product update. Please log in for a seamless experience with the latest version.`);
            if(passThroughAccess){
                window.location.href = config.pmtEndSignInEndPoint;
            }else{
              setTimeout(function(){
                redirectPageToLogin(true);
            }, 6000); 
            }
          }
            setLocalStorageValue(
              "lastRefreshDate",
              JSON.stringify(response?.data?.data?.last_refreshed_date)
            );
            setLocalStorageValue(
              "nextRefreshDate",
              JSON.stringify(response?.data?.data?.next_refresh_date)
            );
        }
        } else {
            console.log("ERROR", "There is an error in data response for latest refresh date!");
            //toast.error("There is an error in data response for latest refresh date!");
        }
    })
    .catch((error) => {
        console.log("ERROR", error);
        //toast.error("There is an error in data response for latest refresh date");
    });
}

export function getUserTimeZone() {
    try {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return userTimeZone;
    } catch (error) {
        console.log(error)
    }
}

export function getPricingIntervals(default_frequency = "default_frequency") {
  let options = [];
  let tenantPricingCompetitorFrequency = localStorage.getItem('tenantPricingCompetitorFrequency');

  if (tenantPricingCompetitorFrequency) {

    tenantPricingCompetitorFrequency = JSON.parse(tenantPricingCompetitorFrequency);

    if (default_frequency != 'default_frequency' && tenantPricingCompetitorFrequency[default_frequency]) {

      if (tenantPricingCompetitorFrequency.sales_frequency.daily == "True") {
        options.push({
          "label": "Day",
          "value": "daily",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "daily")
        });
      }
      
      if (tenantPricingCompetitorFrequency.sales_frequency.weekly == "True") {
        options.push({
          "label": "Week",
          "value": "weekly",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "weekly")
        });
      }
      
      if (tenantPricingCompetitorFrequency.sales_frequency.monthly == "True") {
        options.push({
          "label": "Month",
          "value": "monthly",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "monthly")
        });
      }
      
      if (tenantPricingCompetitorFrequency.sales_frequency.yearly == "True") {
        options.push({
          "label": "Year",
          "value": "yearly",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "yearly")
        });
      }

    } else if (tenantPricingCompetitorFrequency.frequency) {
      
      if (tenantPricingCompetitorFrequency.frequency.daily == "True") {
        options.push({
          "label": "Day",
          "value": "daily",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "daily")
        });
      }
      
      if (tenantPricingCompetitorFrequency.frequency.weekly == "True") {
        options.push({
          "label": "Week",
          "value": "weekly",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "weekly")
        });
      }
      
      if (tenantPricingCompetitorFrequency.frequency.monthly == "True") {
        options.push({
          "label": "Month",
          "value": "monthly",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "monthly")
        });
      }
      
      if (tenantPricingCompetitorFrequency.frequency.yearly == "True") {
        options.push({
          "label": "Year",
          "value": "yearly",
          "selected": (tenantPricingCompetitorFrequency[default_frequency] == "yearly")
        });
      }

    }

  } else {
    options.push({
      "label": "Day",
      "value": "daily",
      "selected": true
    });
  }
//   console.log('default_frequency', default_frequency, options);
//   console.log('options', options);
  return options;
}

export function versionName() {
  try {
    let versionFile = require("../Login/version.json");
    if (process.env.REACT_APP_NODE_ENV == 'prod') {
      return `${versionFile?.version}`;
    } else {
      return `${process.env.REACT_APP_NODE_ENV} ${versionFile?.version}`;
    }
  } catch {
    return "00.00.00";
  }
};

export function copyrightYear() {
  return "2024";
}