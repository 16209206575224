import React, { useState, useContext, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown, Button, ButtonGroup ,FormControl} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { generateRefreshDate } from "../../../utils/routeBasedOperations";
import Select from 'react-select';
import { getUserSavedFilters, deleteUserFilter } from "../UserPersonalizedFilters/SavePersonalizedFilterModal";
import { apiCall, columnsMapping, isThisFeatureAllowed, toolTipMessage } from "../common";
import UserPersonalizedFilterContext from "../../../contextApi/UserPersonalizedFilterContext";
import { FeatureNotEnabled, LIVEPRICE, SAVED_FILTER, UserPersonalizedFilters, filtersApi, livePriceCheckApi , filterOptionApi } from "../constants";
import { contextApiDefaultValueOnClear } from "../Layout/Layout";
import SideMenuCommonFilterLib from "../../../contextApi/SideMenuCommonFilterLib";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash, faInfoCircle ,faBookmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router";
import * as pageRoutes from "../../../pageRoutes";
import { getLocalStorageValue } from "../../../utils/localStorageOperations";
import moment from "moment";
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import AsyncSelect from 'react-select/async';

const BreadcrumbView = (props) => {
    const {
        breadcrumbConfig,
        refreshConfig,
        updateData,
        filterBtnsSelected,
        showSavedFilters=true,
        showLandscapeSection=false,
        moreData,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [UserPersonalizedFilterValue, setUserPersonalizedFilterValue] = useContext(UserPersonalizedFilterContext);
    const [firstTimeLoadSavedFilter, setFirstTimeLoadSavedFilter] = useState(0);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [noOptionsMessage, setNoOptionsMessage] = useState("Saved Filters");
    const [openSearch, setOpenSearch] = useState(false);
    const [currentSku, setCurrentSku] = useState("");
    const [livePrices, setLivePrices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rescanWaitTime, setRescanWaitTime] = useState(1);
    const [selectedOption, setSelectedOption] = useState("product_title");
    const [selectedSKUOption, setSelectedSKUOption] = useState(null);
    const [module, setModule] = useState(refreshConfig.module);

    useEffect(() => {
        if (Object.keys(sideMenuCommonFilterLibContext).length <= 5) {
            //loadUserSearches();
            getUserSavedFilters(setUserPersonalizedFilterValue, setNoOptionsMessage);
        }
    }, [sideMenuCommonFilterLibContext]);

    useEffect(() => {
        if (moreData?.id) {
            setCurrentSku(moreData?.id);
        }
    }, [moreData?.id]);

    useEffect(() => {
        if (isThisFeatureAllowed(LIVEPRICE) && currentSku.length) {
            getLivePrice();
        }
    }, [currentSku]);

    useEffect(() => {
        if (UserPersonalizedFilterValue.allSavedFilter && firstTimeLoadSavedFilter == 0 && filterBtnsSelected) {
            let selectedItem = UserPersonalizedFilterValue.allSavedFilter.find(savedFilter => {
                return (!savedFilter.is_user_filter && savedFilter.is_default);
            });
            if (selectedItem && Object.keys(selectedItem).length) {
                setFirstTimeLoadSavedFilter(1);
                onChangeFilterOptions({value:selectedItem.id});
                let newUserPersonalizedFilterValue = {...UserPersonalizedFilterValue};
                newUserPersonalizedFilterValue.allSavedFilter.map(allSavedFilterSingle => {
                    if (allSavedFilterSingle.is_default) {
                        allSavedFilterSingle.isBenchMark = true;  
                    }
                    allSavedFilterSingle.is_default = false;
                    return allSavedFilterSingle;
                });
                setUserPersonalizedFilterValue(newUserPersonalizedFilterValue);
            }
        }
    }, [firstTimeLoadSavedFilter, UserPersonalizedFilterValue]);

    useEffect(() => {
        // console.log('currentModule', localStorage.getItem('currentModule'), module, sideMenuCommonFilterLibContext.retailers);
        if(localStorage.getItem('currentModule')!=module || (sideMenuCommonFilterLibContext?.retailers && sideMenuCommonFilterLibContext.retailers.length <= 1)){
          localStorage.setItem('currentModule',module);
          getFilters()
        }
    }, []);

    const getFilters = () => {
        apiCall("POST", filtersApi, {}, {
            filters: {},
            modules: localStorage.currentModule
        })
        .then((response) => {
            if (response?.data) {
                let newSideMenuCommonFilterLibContext = {};
                if(module == 'assortment') {
                    response.data.data.source = response.data.data.source.Assortment
                } else if(module == 'pricing') {
                    response.data.data.source = response.data.data.source.Pricing
                } else if(module == 'promotion') {
                    response.data.data.source = response.data.data.source.Promotion
                } else {
                    response.data.data.source = [];
                }
                const filterConfigValues = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["filter_config_values"];
                newSideMenuCommonFilterLibContext.retailerLib = response.data.data.source;
                newSideMenuCommonFilterLibContext.imageRetailerLib = response?.data?.data?.img_source;
                newSideMenuCommonFilterLibContext.category1Lib = response.data.data.tenant_category;
                newSideMenuCommonFilterLibContext.subcategory1Lib = response.data.data.tenant_subcategory;
                newSideMenuCommonFilterLibContext.subsubcategory1Lib = response.data.data.tenant_sub_subcategory;
                newSideMenuCommonFilterLibContext.subsubsubcategory1Lib = response?.data?.data?.tenant_sub_sub_subcategory;
                newSideMenuCommonFilterLibContext.retailers = [].concat(Object.values(response?.data?.data?.source)).sort();
                newSideMenuCommonFilterLibContext.imageRetailers = [].concat(Object.values(response.data.data.img_source)).sort();
                newSideMenuCommonFilterLibContext.categories = [].concat(Object.values(response.data.data.tenant_category)).sort();
                newSideMenuCommonFilterLibContext.subcategories = [].concat(Object.values(response.data.data.tenant_subcategory)).sort();
                newSideMenuCommonFilterLibContext.subsubcategories = [].concat(Object.values(response.data.data.tenant_sub_subcategory)).sort();
                newSideMenuCommonFilterLibContext.subsubsubcategories = [].concat(Object.values(response?.data?.data?.tenant_sub_sub_subcategory)).sort();
                if(filterConfigValues != null && 'priority_filter' in filterConfigValues){
                    newSideMenuCommonFilterLibContext.priorities = filterConfigValues['priority_filter'];
                }
                else{
                    newSideMenuCommonFilterLibContext.priorities = ['Very High', 'High', 'Medium', 'Low'];
                }
                newSideMenuCommonFilterLibContext.postalCode = response?.data?.data?.postal_code;
                newSideMenuCommonFilterLibContext.compStockStatusFlag = response?.data?.data?.comp_stock_status_flag;
                newSideMenuCommonFilterLibContext.baseStockStatusFlag = response?.data?.data?.tenant_stock_status_flag;
                newSideMenuCommonFilterLibContext.price_relativity = response?.data?.data?.price_relativity;
                newSideMenuCommonFilterLibContext.tenant_zipcode = response?.data?.data?.tenant_zipcode;
                newSideMenuCommonFilterLibContext.comp_zipcode = response?.data?.data?.comp_zipcode;
                //setFilterContext(newSideMenuCommonFilterLibContext);
                if (newSideMenuCommonFilterLibContext.comp_zipcode && newSideMenuCommonFilterLibContext.comp_zipcode.length) {
                    let countryCode = newSideMenuCommonFilterLibContext.comp_zipcode[0]['country_code'];
                    localStorage.setItem('countryCode',countryCode);
                }
                setSideMenuCommonFilterLibContext(newSideMenuCommonFilterLibContext);
            }
          }
          ).catch((error) => {
            console.log("ERROR", error);
          });
    
    }; 

    const generateBreadCrumb = () => {
        return (<nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
                {breadcrumbConfig.map(breadcrumbConfigSingle => {
                    return breadcrumbConfigSingle.href ?
                        <li key={breadcrumbConfigSingle.title} className="breadcrumb-item"><NavLink className='pre-elements' to={breadcrumbConfigSingle.href}>{breadcrumbConfigSingle.title}</NavLink></li> :
                        <li key={breadcrumbConfigSingle.title} className="breadcrumb-item active" aria-current="page">{breadcrumbConfigSingle.title}</li>
                })}
            </ol>
        </nav>);
    };

    const loadFocus = () => {
        setNoOptionsMessage("Loading...")
        getUserSavedFilters(setUserPersonalizedFilterValue, setNoOptionsMessage);
    };

    const onChangeFilterOptions = (item) => {
        let newSideMenuCommonFilterContext  = {...sideMenuCommonFilterContext};
        const newFitlerSelected = {};
        Object.keys(filterBtnsSelected).map(filterKey => {
            newFitlerSelected[filterKey] = contextApiDefaultValueOnClear[filterKey];
        });
        if (item) {
            setUserPersonalizedFilterValue(prevstate => { return { ...prevstate, ...item } });
            let [savedValues] = UserPersonalizedFilterValue.allSavedFilter.filter(singleItem => singleItem.id === item.value);
            savedValues = columnsMapping(savedValues.filterconfigvalues);
            savedValues.savedFilter = [savedValues];
            savedValues.user = newFitlerSelected.user;
            console.log('newFitlerSelected', newFitlerSelected, savedValues);
            updateData({ ...newFitlerSelected, ...savedValues });
            setSideMenuCommonFilterContext({...newFitlerSelected, ...savedValues});
        } else {
            setUserPersonalizedFilterValue({});
            updateData(newFitlerSelected);
        }
    };

    const deleteFilterOption = (id) => {
        deleteUserFilter(id, function(){
            getUserSavedFilters(setUserPersonalizedFilterValue, setNoOptionsMessage);
        });
    };

    const handleChange = (option) => {
        setSelectedSKUOption(option);
        searchSku(option.value);
    };

    const renderSavedFilters = () => {
        /*return (<Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            isClearable
            isSearchable={false}
            options={UserPersonalizedFilterValue.dynamicOptions || []}
            onFocus={loadFocus}
            placeholder="Select..."
            value={UserPersonalizedFilterValue.label ? { 'label': UserPersonalizedFilterValue.label, 'value': UserPersonalizedFilterValue.value } : null}
            defaultValue={UserPersonalizedFilterValue.label ? { 'label': UserPersonalizedFilterValue.label, 'value': UserPersonalizedFilterValue.value } : null}
            onChange={onChangeFilterOptions}
            noOptionsMessage={() => noOptionsMessage}
        />)*/
        return (UserPersonalizedFilterValue.allSavedFilter && UserPersonalizedFilterValue.allSavedFilter.filter(savedFilter => {
            return !savedFilter.is_user_filter;
        }).length ? <Dropdown as={ButtonGroup} >
            <Dropdown.Toggle 
                variant="dark"
                className="btn btn-sm rounded-circle saved-user-filters-icon" 
                id="dropdown-custom-1"
                style={{
                    position: 'absolute',
                    top: '-14px',
                    left: '9px',
                    minWidth: "25px",
                    color: "#fff"
                }}
            >{UserPersonalizedFilterValue.allSavedFilter.filter(savedFilter => {
                return !savedFilter.is_user_filter;
            }).length}</Dropdown.Toggle>
            <Dropdown.Menu>
                {UserPersonalizedFilterValue.allSavedFilter.filter(savedFilter => {
                    return !savedFilter.is_user_filter;
                }).map(savedFilter => {
                    return (<Dropdown.Item style={{minWidth: "230px", cursor: "auto", paddingLeft: "1px", paddingRight: "1px"}}>
                       {(savedFilter.is_default || savedFilter.isBenchMark) ? <>
                       <Button 
                            variant="link"
                            style={{
                                width:"22px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                "text-align": "left",
                                "text-overflow": "ellipsis"
                            }} 
                            onClick={e => {
                                //onChangeFilterOptions({value:savedFilter.id});
                            }}
                            className="text-decoration-none text-dark" 
                        > <FontAwesomeIcon icon={faBookmark} />
                        </Button></> 
                        :
                        <>
                        <Button 
                             variant="link"
                             style={{
                                 width:"22px",
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 "text-align": "left",
                                 "text-overflow": "ellipsis"
                             }} 
                             onClick={e => {
                                 //onChangeFilterOptions({value:savedFilter.id});
                             }}
                             className="text-decoration-none text-dark" 
                         >
                         </Button></> 
                        }
                        <Button 
                            variant="link" 
                            style={{
                                width:"140px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                "text-align": "left",
                                "text-overflow": "ellipsis"
                            }} 
                            onClick={e => {
                                onChangeFilterOptions({value:savedFilter.id});
                            }}
                            className="text-decoration-none text-dark" 
                            title={savedFilter.filterconfigname}
                        >{savedFilter.filterconfigname}</Button>
                        <Button 
                            variant="link" 
                            className="text-decoration-none text-dark float-end" 
                            onClick={e => {
                                deleteFilterOption(savedFilter.id);
                            }}
                        ><FontAwesomeIcon icon={faTrash} /></Button>
                    </Dropdown.Item>);
                })}
            </Dropdown.Menu>
      </Dropdown> : <Button 
            variant="outline-dark" 
            className="btn btn-sm rounded-circle saved-user-filters-icon" 
            style={{
                position: 'absolute',
                top: '-5px',
                right: '6px',
                minWidth: "25px",
            }}
        >0</Button>);
    };

    const searchSku = (sku) => {
        if (sku && sku.length) {
            navigate(pageRoutes.tempPage);
            setTimeout(function(){
                navigate(pageRoutes.productPricingRulePage.replace(":id", sku));
            }, 0);
        }
        setOpenSearch(false); 
    };

    const isRescanAvailable = () => {
        let disable = true;
        if (livePrices.length) {
            let livePrice = livePrices[0];
            if (livePrice.status == "RESCAN_COMPLETED" || livePrice.status == "RESCAN_FAILED") {
                let crawlTime = moment(moment(livePrice.crawl_date).format("YYYY-MM-DDTHH:mm:ss"));
                let currentTime = moment(moment(new Date()).utc().format(" YYYY-MM-DDTHH:mm:ss"));
                let difsMinues = currentTime.diff(crawlTime, 'minutes');
                if (difsMinues >= rescanWaitTime) {
                    disable = false;
                }
            }
        } else {
            disable = false;
        }
        if (loading) {
            disable = true;
        }
        return disable;
    };

    const scanContent = () => {
        let content = <>
            Scan 
            <div className="progress" style={{height: '0.1rem'}}></div>
        </>;
        let toolTipMessageStr = "Rescan Required";
        if (livePrices.length) {
            let livePrice = livePrices[0];
            toolTipMessageStr = livePrice['status'].split('_')[1];
            if (livePrice.status == "RESCAN_COMPLETED" || livePrice.status == "RESCAN_FAILED") {
                content = <>
                    Scanned {convertDate(livePrice['crawl_date'])}
                </>;
                let crawlTime = moment(moment(livePrice.crawl_date).format("YYYY-MM-DDTHH:mm:ss"));
                let currentTime = moment(moment(new Date()).utc().format(" YYYY-MM-DDTHH:mm:ss"));
                let difsMinues = currentTime.diff(crawlTime, 'minutes');
                if (difsMinues < rescanWaitTime) {
                    toolTipMessageStr += ` - Next rescan will be available in ${rescanWaitTime - difsMinues} minutes`;
                }
            } else if (livePrice.status == "RESCAN_SCHEDULED") {
                content = <>
                    30% Scanning
                    <div className="progress" style={{height: '0.1rem'}}>
                        <div className="progress-bar" style={{width: `30%`, background: "#0071ce"}}></div>
                    </div> 
                </>;
            } else if (livePrice.status == "RESCAN_STARTED") {
                content = <>
                    60% Scanning
                    <div className="progress" style={{height: '0.1rem'}}>
                        <div className="progress-bar" style={{width: `60%`, background: "#0071ce"}}></div>
                    </div> 
                </>;
            }
        };
        if (loading) {
            content = "loading...";
            toolTipMessageStr = "Pending";
        }
        return (<span title={toolTipMessageStr} className="cursor-pointer">{content}</span>)
    };

    const rescanPage = () => {
        if (livePrices.length) {
            updateLivePrice();
        } else {
            createLivePrice();
        }
    };

    const convertDate = (capture_date_int) => {
        let date = moment(capture_date_int).format('YYYY-MM-DD HH:mm');
        return date;
    };

    const getLivePrice = () => {
        console.log('currentSku', currentSku);
        setLoading(true);
        let queryParams = {
            base_sku: currentSku,
            type: 'single_product'
        };
        apiCall("GET", livePriceCheckApi, {}, queryParams)
        .then((response) => {
            setLoading(false);
            if (response?.data && response?.data?.data) {
                setLivePrices(response.data.data);
                if (response.data.data.length) {
                    let livePrice = response.data.data[0];
                    if (livePrice.status != "RESCAN_COMPLETED" && livePrice.status != "RESCAN_FAILED") {
                        setTimeout(() =>{
                            getLivePrice();
                        }, 10000)
                    }
                }
            } else {
                console.log("some error");
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });
    };

    function DropdownSKU() {
        const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        };
            return (
                <div>
                <FormControl
                    className="form-select me-2"
                    style={{ height: '30px' , borderRight: 'none', borderLeft: 'none', borderTop: 'none', "--bs-text-opacity": 1 ,color: "#6c757d" }}
                    as="select"
                    value={selectedOption}
                    onChange={handleSelectChange}
                >
                    <option value="product_title"> Product </option>
                    <option value="sku">SKU</option>
                    <option value="upc">UPC</option>
                </FormControl>
                </div>
            );
    }

    const createLivePrice = () => {
        if (moreData?.detail) {
            let detail = moreData.detail;
            let id = moreData.id;
            let detailProds = moreData.detailProds;
            if ((!detail.tenant_size || !detail.tenant_uom) || (detail.tenant_size=='' || detail.tenant_uom=='')) {
                toast.error('Unable to trigger rescan size or uom is empty');
                return;
            }
            if (!(id && id.length > 0) || !(detail.tenant_product_title && detail.tenant_product_title.length > 0) || !(detail.tenant_image_url && detail.tenant_image_url.length > 0) || !(detail.my_price && detail.my_price.length > 0)) {
                toast.warning('Unable to trigger rescan for this product, Please contact Bungee');
                return;
            }
            if (detailProds.length == 0) {
                toast.warning('Unable to trigger rescan for this product, Please contact Bungee');
                return;
            }
            let ruleIds = '';
            if (moreData?.ruleListData) {
                ruleIds = moreData.ruleListData.map(rule => {
                    return rule.rule_id;
                }).join(',');
            }
            let postBody = {
                "base_sku": id,
                "base_img": detail.tenant_image_url,
                "base_title": detail.tenant_product_title,
                "base_uom": detail.tenant_uom,
                "base_brand": detail.tenant_brand,
                "base_size": detail.tenant_size === "" ? null : detail.tenant_size,
                "base_effective_price": detail.my_price,
                "base_shipping_price": detail.tenant_shipping_cost,
                "base_category": detail.tenant_category,
                "rule_ids": ruleIds,
                "base_stockstatus": detail.tenant_stock_status_flag,
                "trigger_useremail": getLocalStorageValue("email")
            }
            let comp_details = [];
            for (let prod of detailProds) {
                comp_details.push({
                    'comp_source_store': prod.comp_source.toLowerCase() + '_' + prod.comp_source.toLowerCase(),
                    'comp_source': prod.comp_source.toLowerCase(),
                    'comp_store': prod.comp_source.toLowerCase(),
                    'comp_sku': prod.comp_sku,
                    'comp_url': prod.comp_product_url,
                    'comp_uom': prod.comp_uom,
                    'comp_size': prod.comp_size,
                    'comp_image_url': prod.comp_image_url,
                    'comp_product_title': prod.comp_product_title,
                    'comp_promo_price': prod.comp_promo_price === 'inf'? null: prod.comp_promo_price,
                    'comp_equalized_price': prod.comp_equalized_price === 'inf'? null: prod.comp_equalized_price,
                    'comp_shipping_price': prod.comp_shipping_cost === 'inf'? null: prod.comp_shipping_cost,
                    'comp_list_price': prod.comp_list_price === 'inf'? null: prod.comp_list_price,
                    'comp_postal_code': prod.comp_postal_code,
                    'comp_brand': prod.comp_brand,
                    'ppu': prod.comp_ppu,
                    'cpi': prod.cpi,
                    'comp_stockstatus': prod.comp_stock_status_flag,
                    'match_type': (detail.tenant_size===prod.comp_size && detail.tenant_size===prod.comp_size)?'exact':'equivalent'
                });
            }
            postBody.comp_details = comp_details;
            console.log('postBody', postBody);
            setLoading(true);
            apiCall("POST", livePriceCheckApi, {}, postBody)
            .then((response) => {
                getLivePrice();
            }).catch((error) => {
                console.log(error);
                getLivePrice();
            })
        } else {
            toast.error('Unable to trigger rescan product details not avalable');
            return;
        }
    };

    const updateLivePrice = () => {
        let putBody = {
            base_sku: currentSku,
            trigger_useremail: getLocalStorageValue("email"),
            type: 'trigger_rescan'
        };
        setLoading(true);
        apiCall("PUT", livePriceCheckApi, {}, putBody)
            .then((response) => {
                getLivePrice();
            }).catch((error) => {
                console.log(error);
                getLivePrice();
            })
    };

    const loadOptions = async (inputValue) => {
        try {
            let payload = {
                "searchKey": selectedOption,
                "text": inputValue,
                "offset": 0,
                "filters": {},
                "size": 50
            };

            let new_url = filterOptionApi + '/sku';

            // Return the promise from apiCall
            return apiCall("POST", new_url, "", payload)
                .then((response) => {
                    setLoading(false);
                    if (response?.data && response?.data?.data) {
                         if (response.data.data.length) {
                            // Transform the response data into options format
                            const options = response.data.data.map(item => ({
                                value: item.value,
                                label: item.label
                            }));
                            return options; // Resolve with the options data
                        } else {
                            return []; // Return an empty array if no options found
                        }
                    } else {
                        console.log("some error");
                        return []; // Return an empty array in case of error
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    return []; // Return an empty array in case of error
                });
        } catch (error) {
            console.error('Error fetching options:', error);
            return []; // Return an empty array in case of error
        }
    };

    const colourStyles = {
        control: styles => ({
        ...styles,
        minHeight: '30px',
        height: '30px',
        backgroundColor: 'white',
        borderRight: 'none', 
        borderLeft: 'none',
        borderTop: 'none',
        borderRadius: '0',
    }),
    };
    
    const isSavedFilterEnabled = isThisFeatureAllowed(SAVED_FILTER);
    return (<><div className='custom-container breadcrum-container'>
        <Row className="mb-0 g-0">
            {props.hideBreadCrumb ? "" : <Col className="mt-1 ps-3 pt-2">{generateBreadCrumb()}</Col>}
            {showLandscapeSection ? <>
                <Col className="mt-1 ps-3 pt-2"></Col>
                <Col className="ps-3 p-2" sm="5">
                    <div className="position-relative float-end d-flex justify-content-center flex-column h-100">
                        {/* <div>
                            {!isThisFeatureAllowed(LIVEPRICE) ? <div className="blurContainer d-flex justify-content-center align-items-center" title={FeatureNotEnabled}><button className="btn btn-light w-100">Rescan</button></div> : ""}
                            <div className="float-end d-flex justify-content-center flex-column h-100">
                                <button 
                                    className="ms-3 btn btn-light btn-sm" 
                                    disabled={isRescanAvailable()} 
                                    onClick={e => rescanPage()}
                                >&#8635; Rescan</button>
                            </div>
                            <div className="float-end d-flex justify-content-center flex-column h-100">
                                {scanContent()}
                            </div>
                        </div> */}
                    </div>
                    <div className="float-end d-flex justify-content-center flex-column h-100">
                        <div className="me-3 pe-3">
                            {<div className="input-group" style={{"width":"350px","zIndex":1030}} >
                                {DropdownSKU()}
                                <AsyncSelect
                                    loadOptions={loadOptions}
                                    isSearchable
                                    className="form-control border-0 pt-0" 
                                    placeholder="Search..." 
                                    styles={colourStyles}
                                    onChange={handleChange} // Handle selection change
                                />
                            </div>}
                        </div>
                    </div>
                </Col>
            </> : <>
                <Col className="mt-1 ps-3 pt-2" style={!isSavedFilterEnabled ? {marginRight:'50px'} : {}}>{props.hideRefresh ? "" : refreshConfig ? generateRefreshDate(refreshConfig) : generateRefreshDate()}</Col>
                {showSavedFilters && isSavedFilterEnabled && <Col className="mt-1 pt-2" sm="1" style={{ marginRight: '50px' }}><Row className="g-0"><Col className="ps-3 mb-2" style={{
                    position: 'relative'
                }}>Saved Filters {renderSavedFilters()}</Col></Row></Col>}
            </>}
        </Row>
    </div>
        <hr className='section-divider' />
    </>);
    
};
export default BreadcrumbView;